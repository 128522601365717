<template>
  <CardList :cards="cardDetailList" :title="content.config?.title?.value" />
</template>

<script setup lang="ts">
import { type CmsElement } from "@/types/cms";
import type { Media } from "@shopware-pwa/types";
import CardList from "@/components/molecules/Card/CardList";
import { htmlParser } from "@/utils/htmlParser";
import { mediaUpdater } from "@/utils/mediaUpdater";
import { useInternalLinkMapper } from "@/composables/useInternalLinkMapper";

interface CardDetails {
  media: {
    value: Media;
  };
  createdAt: number;
  textContent: {
    value: string;
  };
}

const props = defineProps<{
  content: CmsElement<{
    cardDetails: CardDetails[];
    title?: string;
  }>;
}>();

const elementData = props.content.data as {
  cardDetails: CardDetails[];
};

const cardDetailList = elementData?.cardDetails?.map((cardDetail) => {
  const cardDisplayOptions = {
    media: cardDetail?.media?.value,
    title: "",
    link: {
      to: "",
      text: "",
    },
    text: "",
  };

  const cardDetailFromHTML = htmlParser(cardDetail.textContent.value);

  const headingTags = ["h1", "h2", "h3", "h4", "h5", "h6"];

  const cardDetailTitle = cardDetailFromHTML?.find((element) =>
    headingTags.includes(element.tag),
  );

  const cardDetailLink = cardDetailFromHTML?.find(
    (element) => element.tag === "a",
  );

  const cardDetailText = cardDetailFromHTML?.find(
    (element) => element.tag === "p",
  );

  if (cardDetailTitle?.value) {
    cardDisplayOptions.title = `<${cardDetailTitle.tag}${cardDetailTitle?.attributes ? " " + cardDetailTitle?.attributes : ""}>${cardDetailTitle.value}</${cardDetailTitle.tag}>`;
  }

  if (cardDetailLink?.value) {
    cardDisplayOptions.link.text = cardDetailLink.value;
    cardDisplayOptions.link.to = cardDetailLink?.parsedAttributes?.href;
  }

  if (cardDetailText?.value) {
    cardDisplayOptions.text = cardDetailText?.value;
  }
  return cardDisplayOptions;
});
</script>
